import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ClientConfiguration } from '../models/client-configuration';

@Injectable({
  providedIn: 'root'
})
export class ClientConfigService {
  configObservable: Observable<ClientConfiguration>

  constructor(private http: HttpClient) { }

  fetchConfig(): Observable<ClientConfiguration> {
    if (!this.configObservable) {
      this.configObservable = of({timezone: 'America/Chicago'})
    }
    
    return this.configObservable;
  }
}
