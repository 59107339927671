import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DepartmentService } from '../services/department.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  passwordInvalid = false;
  emailInvalid = false;
  accountCreationMessage: string;
  passwordResetMessage: string;
  navigationSubscription: Subscription;
  loginDisplay = false;

  private readonly _destroying$ = new Subject<void>();


  constructor(private azureAuthService: MsalService,
              private broadcastService: MsalBroadcastService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.broadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.azureLogin();
    })
  }

  ngOnDestroy() {
    this.navigationSubscription?.unsubscribe();
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  azureLogin() {
    const currentAccount = this.azureAuthService.instance.getAllAccounts()[0];
    let roles = currentAccount?.idTokenClaims?.roles;

    if (!!currentAccount) {
      this.azureAuthService.acquireTokenSilent({account: currentAccount} as SilentRequest).subscribe(result => {}, error => this.azureAuthService.loginRedirect);
      if(roles && (roles.includes('Volunteer.Staff') || roles.includes('Volunteer.Admin'))) {
        this.router.navigate(['/admin'])
      } else if (roles && roles.includes('Volunteer.User')) {
        this.router.navigate(['/volunteer'])
      } else {
        this.router.navigate(['/not-authorized'])
      }
    } else {
      this.azureAuthService.loginRedirect();
    }
  }
}