import {Inject, Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor,HttpRequest,HttpResponse,HttpErrorResponse} from '@angular/common/http';
import {Observable, of, throwError} from "rxjs";
import {catchError, map, tap} from 'rxjs/operators';
import { Router } from "@angular/router";
import { LoadingSpinnerService } from "./loading-spinner.service";
 
@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  requests: String[] = [];
    
  constructor(public router: Router,
              private spinnerService: LoadingSpinnerService,
              @Inject('BASE_API_URL') private baseUrl: string) {
  }
 
  /**
   * Intercepts all HTTP requests made from the client, ensuring that the session cookie
   * is sent with each request. Also will log the user out and end their session if
   * an "unauthorized" response is received from the server.
   * 
   * @param req The request to be sent to the server
   * @param next The HTTPHandler that will forward the request
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.requests.length === 0) {
      this.spinnerService.show();
    }
    this.requests.push(req.url);

    if (!req.url.startsWith('http')) {
      req = req.clone({
        url: `${this.baseUrl}${req.url}`,
        withCredentials: true
      });
    } else {
      req = req.clone({
        withCredentials: true
      });
    }
 
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
            this.requests.pop();
            if (this.requests.length === 0) {
              this.spinnerService.hide();
            }
        }
    }, (error) => {
        this.requests.pop();
        if (this.requests.length === 0) {
          this.spinnerService.hide();
        }
        if (error.status === 401) {
          this.router.navigate(['login'])
        }
        return throwError(error);
    })
    )
  }
}