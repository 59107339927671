import { Injectable } from "@angular/core";
import { ClientConfiguration } from "../models/client-configuration";
import { ClientConfigService } from "../services/client-config.service";

@Injectable({
    providedIn: 'root'
  })
  export class ClientConfigProvider {
    private _clientConfig: ClientConfiguration;

    get clientConfig(): ClientConfiguration {
        return this._clientConfig
    }
  
    constructor(private clientConfigService: ClientConfigService) { }
  
    load() {
      return new Promise(resolve => {
          this.clientConfigService.fetchConfig().subscribe(config => {
            this._clientConfig = config;
            resolve(true);
          });
      });
    }
  }
  