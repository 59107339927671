import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ClientConfigProvider } from './providers/client-config-provider';
import { LoadingSpinnerService } from './services/loading-spinner.service';
import * as moment from 'moment-timezone';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { InteractionStatus, SilentRequest } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'vms-ui';
  isLoading: boolean = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
              public spinnerService: LoadingSpinnerService,
              private clientConfigProvider: ClientConfigProvider,
              private azureAuthService: MsalService,
              private router: Router,
              private snapshot: ActivatedRoute,
              private broadcastService: MsalBroadcastService) {}

  ngOnInit() {
    moment.tz.setDefault(this.clientConfigProvider.clientConfig.timezone);

    this.spinnerService.visibility.subscribe(visibility => {
      this.isLoading = visibility;
    })
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
